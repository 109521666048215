// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// FontAwesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';
@import '~overlayscrollbars/css/OverlayScrollbars.css';

.vs__search::placeholder {
  color: #939ba2 !important;
  opacity: 1 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.flash-wrapper {
  position: fixed;
  right: 2em;
  z-index: 999999;
}

.form-group.required > label:after {
  content: "*";
  color: red;
  margin-left: 0.2em;
}

.txt-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.vdp-datepicker .input-group .form-control[readonly]:not([disabled]) {
  background: none !important;
}

@media screen {
  #printSection {
    display: none;
  }
}

@media all {
  .page-break {
    display: none;
  }
}
